@use '../../reference';

.text {
  font-size: 14px;
  font-weight: reference.$font-weight-semibold;
  margin-block-start: 2px;
  text-decoration: none;

  &:focus-visible,
  &:hover {
    color: reference.$color-primary;
  }

  @media (min-width: 28rem) {
    font-size: 16px;
  }
}

.current {
  color: reference.$color-primary;
  position: relative;

  &::after {
    background-color: reference.$color-primary;
    content: '';
    inset-block-end: calc(var(--header-padding-block) * -1);
    inset-inline: 0;
    height: 2px;
    pointer-events: none;
    position: absolute;

    @media (min-width: 48rem) {
      inset-block-end: calc(var(--header-padding-block) * -1 - 6px);
    }
  }
}
