@use '../../reference';

.header {
  --header-padding-block: 1rem;

  border-block-end: 1px solid reference.$color-grey-200;
  padding-block: var(--header-padding-block);
  position: relative;

  @media (min-width: 28rem) {
    --header-padding-block: 1.5rem;
  }

  body:has([class*='Illustration_header']) & {
    border-block-end: 0;
  }
}

.logo {
  display: block;

  &:focus-visible,
  &:hover {
    opacity: 0.9;
  }
}

.container {
  @include reference.container;

  align-items: center;
  column-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1.5rem;

  @media (min-width: 28rem) {
    justify-content: space-between;
  }
}

.logo {
  color: reference.$color-primary;
}

.navigation {
  display: flex;
  column-gap: 2rem;

  @media (min-width: 28rem) {
    column-gap: 3rem;
  }
}
