@use '../../reference';

.footer {
  --color: var(--color-white);

  background-color: var(--color-primary);
  color: var(--color);
  padding-block: clamp(2rem, 2.5vw, 3rem);
  text-align: center;

  @include reference.dark-mode {
    --color: #{reference.$color-grey-500};

    background-color: var(--color-white);
  }
}

.logo {
  &:focus-visible,
  &:hover {
    opacity: 0.9;
  }
}

.copyrightContainer {
  @include reference.container(42rem);
}

.link {
  overflow: hidden;
  position: relative;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}
