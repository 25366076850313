@use '../../reference';

.socialContainer {
  @include reference.container;

  align-items: center;
  border-block: 1px solid rgba(245, 245, 245, 0.36);
  display: flex;
  flex-wrap: wrap;
  column-gap: 3rem;
  row-gap: 1rem;
  justify-content: center;
  margin-block-end: clamp(2rem, 3vw, 3.5rem);
  padding-block: 1rem;
}

.socialHeading {
  font-weight: reference.$font-weight-semibold;
  margin-block: 0;
}

.socialList {
  display: flex;
  gap: 1.5rem;
  margin-block: 0;
}

.socialIconLink {
  border-radius: 50%;
  display: block;

  &:focus-visible,
  &:hover {
    opacity: 0.9;
  }
}

.socialIcon {
  display: block;
}
