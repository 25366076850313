@use '../../reference';

// Super navigation styles need to match hab.com.

.root {
  background-color: reference.$color-grey-100;
  padding-inline: 1.5rem;
}

.list {
  display: flex;
  gap: 0.875rem;
  list-style: none;
  margin-block: 0;
  margin-inline: auto;
  max-width: 82rem;
  padding: 0;

  @media (min-width: 48rem) {
    gap: 1.25rem;
  }
}

.link {
  align-items: center;
  color: reference.$color-grey-500;
  display: flex;
  font-size: 0.75rem;
  font-weight: reference.$font-weight-semibold;
  height: 3rem;
  position: relative;
  text-decoration: none;

  @media (min-width: 48rem) {
    font-size: 1rem;
  }

  &::after {
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:focus-visible,
  &:hover {
    color: reference.$color-primary;

    &::after {
      background-color: reference.$color-primary;
    }
  }
}

.current {
  color: reference.$color-primary;

  &::after {
    background-color: reference.$color-primary;
  }
}
